
/* -----------Global-------------------*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

/* GLOBAIS */

.whatsapp-button {
    position: fixed;
    right: 10px;
    bottom: 10px;
    transition: all .3s ease;
    cursor: pointer;
}

.whatsapp-button:hover {
    transform: scale(1.1,1.1);
}

.whatsapp-button img {
    width: 60px;
}

.disable-scrolling{
    height: 100%;
    overflow: hidden;
}

.social-icons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icons-a {
    text-decoration: none;
    color: #333333;
}

.default-button {
    padding: 12px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.send-file {
    background-color: rgba(0, 0, 0, 0.459);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    display: none;
    align-items: center;
    justify-content: center;
}

.send-file.active {
    display: flex;
}

.send-file-container {
    width: 800px;
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;

}

.send-file-text {
    font: 20px;
    font-weight: 600;
    margin: 10px 0;
}

.send-file-img {
    width: 100%;
}

.send-file-img img {
    width: 100%;
}



@media only screen and (max-width: 800px) {
    .default-button {
        padding: 8px 12px;
        font-size: 15px;
    }

    .send-file-container {
        border-radius: 0;
        width: 100%;
        box-sizing: border-box;
    }

    .send-file {
        align-items: flex-start;
        justify-content: flex-start;
        overflow: scroll;
    }

    .send-file-text {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 300px) {
    .default-button {
        padding: 5px 5px;
        font-size: 15px;

    }   
}

.wrapper {
    max-width: 1200px;
    width: 100%;
}

/* ------------------------------TRANSITIONS ----------------------------------- */
.fade-left {
    opacity: 0;
}

.fade-left.active {
    animation: fadeLeft 1s forwards ease;
}

.fade-left-pollaroid {
    opacity: 0;
}

.fade-left-pollaroid.active {
    animation: fadeLeftPollaroid 1s forwards ease;
}

.fade-right {
    opacity: 0;
}

.fade-right.active {
    animation: fadeRight 1s forwards ease;
}

.fade-in {
    opacity: 0;
}

.fade-in.active {
    animation: fadeIn 1s forwards ease;
}

@keyframes fadeLeft {
    from {opacity: 0;transform: translateX(-400px)}
    to {opacity: 1;transform: translateX(0)}
}

@keyframes fadeLeftPollaroid {
    from {opacity: 0;transform: translateX(-400px) rotateZ(-4deg);}
    to {opacity: 1;transform: translateX(0) rotateZ(-4deg);}
}

@keyframes fadeRight {
    from {opacity: 0; transform: translateX(400px);}
    to {opacity: 1; transform: translateX(0);}
}

/* ---------------------------------------------HEADER-------------------------------------------------------*/

header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* HEADER - Top Bar */

.top-bar {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-bar .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 40px 15px;
    box-sizing: border-box;
}

.logo {
    display: flex;
}

.logo-mobile {
    display: none;
}

.logo-mobile img {
    width: 200px;
}

.logo img {
    width: 250px;
}

.icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon {
    border: 4px solid #02639b;
    padding: 5px;
    border-radius: 5px;
}

.icon-texts {
    display: flex;
    flex-direction: column;
}

.icon-text {
    font-size: 15px;
    font-weight: 600;
}

.icon-subtext {
    font-size: 16px;
    font-weight: 600;
    color: #8d8d8d;
}

/* HEADER - Menu */

.menu {
    background-image: linear-gradient(to right, #02639b, #074d75);
    display: flex;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
}

.menu.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transform: translateY(-50px);
    animation: menuDown .5s .3s ease forwards;
}

@keyframes menuDown {
    from {transform: translateY(-50px);}
    to {transform: translateY(0);}
}

.menu .wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
}

.nav {
    
    box-sizing: border-box;
}

.mobile-nav {
    display: none;
    position: relative;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
}

.mobile-nav-button {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
}

.mobile-nav-itens {
    position: absolute;
    background-color: white;
    width: 100%;
    top: 50px;
    left: 0;
    z-index: 999;
    flex-direction: column;
    overflow: hidden;
    display: none;
    height: 0;
    animation: closeMenu .5s ease forwards;
}

@keyframes closeMenu {
    0% {height: 175px;}
    99% {height: 0;}
    100% {display: none;}
}

.mobile-nav-itens.active {
    display: flex;
    animation: openMenu .5s ease forwards;
}

@keyframes openMenu {
    from {height: 0;}
    to {height: 175px;}
}

.mobile-nav-itens ul {
    list-style: none;
}

.mobile-nav-itens li {
    padding: 5px 15px;
    box-sizing: border-box;
}

.mobile-nav-itens li:hover {
    background-color: rgb(216, 216, 216);
}

.mobile-nav-itens a {
    text-decoration: none;
    color: #333333;
    font-weight: 500;
}

.nav ul {
    display: flex;
    list-style: none;
    gap: 30px;
}

.nav li {
    padding: 10px 0 5px 0;
    border-bottom: 5px solid transparent;
    transition: .3s all ease;
}

.nav a {
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
    color: white;
    transition: all ease .3s;  
}

.nav li:hover a {
    color: #cfcfcf;
}

.nav li:hover {
    border-bottom: 5px solid #cfcfcf;
}

@media only screen and (max-width: 1100px) {
    .logo {
        display: none;
    }

    .logo-mobile {
        display: flex;
        padding: 20px 0;
    }

    .top-bar .wrapper {
        justify-content: space-evenly;
    }

}

@media only screen and (max-width: 800px) {
    .top-bar .icons-a {
        display: none;
    }

    .nav {
        display: none;
    }

    .mobile-nav {
        display: flex;
    }

}

/* --------------------------------------------------SLIDER-------------------------------------------------- */
.slider {
    position: relative;
    background-color: rgba(0, 0, 0, 0.438);
}

.slider-controls {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    z-index: 99;
    cursor: pointer;
}


.slide {
    display: none;
    justify-content: center;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
}

.slide.active {
    display: flex;
}

.slide-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.555);
    z-index: 0;
}

.slide-content {
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
}

.slide-tittle {
    color: white;
    font-size: 45px;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.1;
}

.slide-text {
    color: white;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    max-width: 60%;
    margin-bottom: 5px;
}

.slide-buttons {
    display: flex;
    gap: 30px;
}

@media only screen and (max-width: 1000px) {
    .slide-tittle {
        font-size: 35px;
    }

    .slide-text {
        max-width: 80%
    }
}

@media only screen and (max-width: 800px) {
    .slide-tittle {
        font-size: 25px;
    }
}

@media only screen and (max-width: 550px) {
    .slide-tittle {
        font-size: 20px;
    }
}

@media only screen and (max-width: 320px) {
    .slide-tittle {
        font-size: 18px;
    }
}

/* --------------------------------------------------ABOUT US------------------------------------------------- */

.about-us {
    display: flex;
}

.about-us p {
    text-align: justify;
}
   
.about-us .wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 80px auto;
    display: flex;
    flex-direction: column;
    gap: 60px;
   
}

.grafica-drumond {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;;
}

.about-us-img {
    flex: 1;
    display: flex;
}

.pollaroid-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 530px;
    min-width: 530px;
    justify-content: center;
    gap: 10px;
}

.pollaroid-style {
    width: 200px;
    height: 170px;
    border-radius: 2px;
    padding: 10px 10px 35px 10px;
    display: flex;
    background-color: rgb(248, 248, 248);
    box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.459);
}

.pollaroid-style:nth-child(1) {
    transform: rotateZ(-1deg);
}

.pollaroid-style:nth-child(2) {
    transform: rotateZ(-1deg);
}

.pollaroid-style:nth-child(3) {
    transform: rotateZ(-1deg);
}

.pollaroid-style:nth-child(4) {
    transform: rotateZ(-1deg);
}


.pollaroid-style img {
    width: 100%;
}

.about-us-text {
    flex: 1;
    display: flex;
}

.about-us-texts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 30px 0 0;
}

.about-us h2 {
    width: max-content;
    font-size: 30px;
    color: #333333;
    font-weight: 600;
    border-bottom: 4px solid #02639b;
}

.about-us-text p {
    max-width: 600px;
    width: 100%;
    font-weight: 300;
}

.about-us-buttons {
    display: FLEx;
    gap: 30px;
}

.visao-valores {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;
}

.visao-valores p {
    font-weight: 300;
}

.video-drumond {
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;
    
}

.drumond-destaques {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 20px;
    box-sizing: border-box;
}
.destaque-title {
    display: flex;
    gap: 10px;
    align-items: center;
}

.destaque {
    flex: 1 0 45%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.destaque p {
    text-align: justify;
}

@media only screen and (max-width: 1045px) {
    .about-us-img {
        justify-content: center;
    }
    .grafica-drumond {
        gap: 30px;
    }

    .pollaroid-style {
        width: 200px;
        height: 170px;
    }

    .about-us-text {
        justify-content: center;
    }
}

@media only screen and (max-width: 980px) {
    .about-us .wrapper {
        justify-content: center;
        gap: 50px;
    }

    .about-us-text {
        min-width: 470px;
        max-width: max-content;
    }

    .about-us-texts {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 550px) {
    .destaque {
        flex: 1 0 90%;
    }
}

@media only screen and (max-width: 550px) {
    .pollaroid-container {
        min-width: inherit;
    }

    .about-us .wrapper {
        margin: 40px 10px;
    }

    .pollaroid-style {
        width: 180px;
        height: 150px;
    }
    .about-us-text {
        min-width: inherit;
    }

    .video-drumond {
        padding: 0 10px;
    }

    .video-drumond iframe{
        width: 100%;
    }

    .drumond-destaques {
        padding: 0 10px;
    }

    .visao-valores {
        padding: 0 10px;
    }

    .about-us-texts {
        padding: 0 10px;
    }
}

@media only screen and (max-width: 450px) {

    .pollaroid-style {
        width: 140px;
        height: 110px;
    }

    .about-us-buttons {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
}

@media only screen and (max-width: 380px) {
    .pollaroid-style {
        width: 200px;
        height: 180px;
    }
}

@media only screen and (max-width: 325px) {
    .pollaroid-style {
        width: 170px;
        height: 150px;
    }

    .about-us-text h2 {
        font-size: 20px;
    }

    .pollaroid-style {
        margin: 0;
    }
}

/* --------------------------------------------------SERVICES------------------------------------------------- */

.services {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    background-color: #e2e2e2;
}

.services .wrapper {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 20px;
    box-sizing: border-box;
}

.services-icons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.services-button {
    align-self: flex-end;
}

.services h2 { 
    width: max-content;
    font-size: 30px;
    color: #333333;
    font-weight: 600;
    border-bottom: 4px solid #02639b;
}

.icon-img {
    border-bottom: 3px solid #e2e2e2;
    height: 275px;
}

.icon-img img{
    width: 100%;
    height: 100%;
}

.icon-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    flex-basis: 275px;
    overflow: hidden;
    background-color: white;
    border-radius: 5px;
    
    box-sizing: border-box;
    transition: all .3s ease-out;
}

.icon-text-container:hover {
    transform: scale(1.05,1.05);
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.493);
}

.icon-texts h4 {
    text-align: center;
}

.icon-texts p {
    font-size: 13px;
}

.icon-button {
    margin-bottom: 20px;
}

.all-services {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 99999;
    align-items: center;
    justify-content: center;
    animation: fadeIn .5s ease;
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

.all-services.active {
    display: flex;
}

.all-services-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.all-services-tittle {
    display: flex;
    justify-content: center;
    background-color: white;
    width: 90%;
    padding: 20px 0;
    border-bottom: 4px solid #02639b;
    position: relative;
    border-radius:  10px 10px 0 0;
}

.all-services-tittle h2{
    border: none;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 6px;
    cursor: pointer;
}

.all-services-list {
    overflow: auto;
    height: 80vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 90%;
    background-color: white;
    padding: 40px;
    box-sizing: border-box;
}

.service {
    width: 250px;
    background-color: #f8f8f8;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.397);
    border-radius: 5px;
    overflow: hidden;
    transition: .3s all ease;
    cursor: pointer;
}

.service:hover {
    transform: scale(1.03,1.03);
}

.service-name {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 17px;
    text-transform: uppercase;
    text-align: center;
    background-color: #02639b;
    color: white;
    height: 40px;
}

.service-text {
    padding: 10px;
    box-sizing: border-box;
    font-weight: 300;
    font-size: 14px;
}


/* --------------------------------------------------CONTACT------------------------------------------------- */

.contact {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.contact h3 {
     border-bottom: 3px solid #02639b;
     width: max-content;
}

.contact-details {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-details-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact .wrapper {
    max-width: 1200px;
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 50px;
}

.contact-icons {
    
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact-form {
    flex: 1;
    display: flex;
    justify-content: center;
}

.contact form {
    display: flex;
    flex-direction: column;
    width: 318px;
    gap: 8px;
}

.contact form input {
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border: none;
    background-color: #f1f1f1;
    border-radius: 5px;
}

.contact form textarea {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    resize:none;
    border: none;
    border-radius: 5px;
    background-color: #f1f1f1;
}

.contact-submit {
    background-color: #02639b !important;
    color: white;
    font-weight: 500;
    cursor: pointer;    
}

.contact-social {
    display: flex;
    gap: 5px;
}

.whatsapp-contact {
    display: flex;
    gap: 5px;
}

.maps {
    height: 400px;
    width: 100%;
    position: relative;
}

@media only screen and (max-width: 360px) {
    .contact form {
        width: 100%;
    }

    .contact .icon-text {
        font-size: 13px;
    }
}

@media only screen and (max-width: 315px) {
    .contact .icon-text {
        font-size: 11px;
    }
}



/* --------------------------------------------------FOOTER------------------------------------------------- */

footer {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(#02639b, #0d3e5a);
}

footer .wrapper {
    max-width: 1200px;
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
}

.footer-logo img {
    width: 250px;
}

.footer-column {
    flex: 1;
    display: flex;
    justify-content: center;
    min-width: 300px;
}

.footer-column.one {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.qr-codes {
    display: flex;
    gap: 10px;
}

.qr-codes img {
    width: 100px;
}

.column-elements {
    display: flex;
    flex-direction: column;
}

.footer-column label {
    color: white;
    font-weight: 700;
    margin-top: 15px;
    font-size: 16px;
}

.footer-column p {
    color: white;
    font-weight: 300;
    font-size: 14px;
}


@media only screen and (max-width: 950px) {
    .footer-column {
        flex-basis: 100%;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 950px) {
    .footer-column {
        min-width: initial;
    }
}

